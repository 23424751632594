import React, { Component, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../../styletheme/logos/gurenter_logo.png";

const GeneralFooter = ({ privacyPolicyLink, termsAndConditionsLink }) => {
  return (
    <React.Fragment>
      {/* FOOTER-3
			============================================= */}
      <footer id="footer-3" className="footer division">
        <div className="container">
          {/* FOOTER CONTENT */}
          <div className="row">
            {/* FOOTER INFO */}
            <div className="col-lg-5 col-xl-4">
              <div className="footer-info mb-40">
                {/* Footer Logo */}
                <Link to={"/"}>
                  <img
                    className="footer-logo mb-25"
                    src={logo}
                    alt="footer-logo"
                    style={{ maxHeight: "70px", width: "150px" }}
                  />
                </Link>

                {/* Text */}
                <p className="p-md">
                  Gurenter simplifies the way rental properties are managed. It
                  also makes it easy for Tenants to be tenants.
                </p>
              </div>
            </div>

            {/* FOOTER LINKS */}
            <div className="col-sm-4 col-lg-2 col-xl-2 offset-xl-2" hidden>
              <div className="footer-links mb-40">
                {/* Title */}
                <h6 className="h6-xl">About</h6>

                {/* Footer Links */}
                <ul className="foo-links text-secondary clearfix">
                  <li>
                    <p className="p-md">
                      <a href="#">About Us</a>
                    </p>
                  </li>
                  <li>
                    <p className="p-md">
                      <a href="#">Careers</a>
                    </p>
                  </li>
                  <li>
                    <p className="p-md">
                      <a href="#">Press & Media</a>
                    </p>
                  </li>
                  <li>
                    <p className="p-md">
                      <a href="#">Advertising</a>
                    </p>
                  </li>
                  <li>
                    <p className="p-md">
                      <a href="#">Contact Us</a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            {/* FOOTER LINKS */}
            <div className="col-sm-4 col-lg-2" hidden>
              <div className="footer-links mb-40">
                {/* Title */}
                <h6 className="h6-xl">Discover</h6>

                {/* Footer List */}
                <ul className="foo-links text-secondary clearfix">
                  <li>
                    <p className="p-md">
                      <a href="#">Our Blog</a>
                    </p>
                  </li>
                  <li>
                    <p className="p-md">
                      <a href="#">Live Chatting</a>
                    </p>
                  </li>
                  <li>
                    <p className="p-md">
                      <a href="#">Plans & Pricing</a>
                    </p>
                  </li>
                  <li>
                    <p className="p-md">
                      <a href="#">Testimonials</a>
                    </p>
                  </li>
                  <li>
                    <p className="p-md">
                      <a href="#">FAQs</a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            {/* FOOTER LINKS */}
            <div className="col-sm-4 col-lg-3 col-xl-2" hidden>
              <div className="footer-links mb-40">
                {/* Title */}
                <h6 className="h6-xl">Connect With Us</h6>

                {/* Social Links */}
                <ul className="footer-socials text-secondary ico-25 clearfix">
                  <li>
                    <a href="#">
                      <span className="flaticon-facebook"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="flaticon-twitter"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="flaticon-github"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="flaticon-youtube"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>{" "}
          {/* END FOOTER CONTENT */}
          <hr />
          {/* BOTTOM FOOTER */}
          <div className="bottom-footer">
            <div className="row row-cols-1 row-cols-md-2 d-flex align-items-center">
              {/* FOOTER COPYRIGHT */}
              <div className="col">
                <div className="footer-copyright">
                  <p>
                    Copyright &copy; {new Date().getFullYear()} Gurenter. All
                    Rights Reserved
                  </p>
                </div>
              </div>

              {/* BOTTOM FOOTER LINKS */}
              <div className="col">
                <ul className="bottom-footer-list text-secondary text-end">
                  <li className="first-li">
                    <p>
                      <a href={privacyPolicyLink} target="_blank">
                        Privacy Policy
                      </a>
                    </p>
                  </li>
                  <li className="last-li">
                    <p>
                      <a href={termsAndConditionsLink} target="_blank">
                        Terms & Conditions
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>{" "}
            {/* End row */}
          </div>{" "}
          {/* BOTTOM FOOTER */}
        </div>
      </footer>{" "}
      {/* FOOTER-3 */}
    </React.Fragment>
  );
};

export default GeneralFooter;
