import http from "../general/httpService";
import api from "../../constants/config.json";


export function createContactMessage(message) {
    const fullApiEndpoint =
        api.apiEndpoint + "/contactManagement/api/createContactMessage";
    return http.post(fullApiEndpoint, message);
}

export function SaveNewsLetterEmail(email) {
    const fullApiEndpoint =
        api.apiEndpoint + "/newsletterManagement/api/createNewsletterEmail";
    return http.post(fullApiEndpoint, email);
}