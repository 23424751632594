import React from "react";
import { Link } from "react-router-dom";

const HomePageTutorials = ({ tutorials }) => {
  return (
    <section id="blog-2" className="blog-section division">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb-40">
              <h2 className="h2-xs">Gurenter Tutorials</h2>
              <p className="p-md">How to use App features</p>
            </div>
          </div>
        </div>
        <div className="row">
          {tutorials &&
            tutorials.map((tutorial, index) => (
              <div key={index} className="col-md-6 col-lg-4">
                <div className="blog-post">
                  <div className="blog-post-img">
                    <Link to={`/tutorial/${tutorial.tutorialTitle}`}>
                      <img
                        className="img-fluid"
                        src={tutorial.tutorialPromoImage}
                        alt={tutorial.tutorialTitle}
                        style={{
                          height: 260,
                          width: 389,
                          objectFit: tutorial.tutorialPlatform
                            .toLowerCase()
                            .includes("ios")
                            ? "cover"
                            : "contain",
                        }}
                      />
                    </Link>
                  </div>
                  <div className="blog-post-txt" style={{ paddingTop: "10px" }}>
                    <Link to={`/tutorial/${tutorial.tutorialTitle}`}>
                      <h5 className="h5-md">{tutorial.tutorialTitle}</h5>
                    </Link>
                    <p className="post-meta">
                      {new Date(tutorial.recordCreatedDate).getDate()}{" "}
                      {new Date(tutorial.recordCreatedDate).toLocaleString(
                        "default",
                        {
                          month: "long",
                        }
                      )}{" "}
                      {new Date(tutorial.recordCreatedDate).getFullYear()}
                    </p>
                    <p className="p-sm">
                      {tutorial.tutorialOverview.length > 200
                        ? tutorial.tutorialOverview.slice(0, 200) + "..."
                        : tutorial.tutorialOverview}
                    </p>
                    <Link
                      to={`/tutorial/${tutorial.tutorialTitle}`}
                      className="btn btn-primary"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default HomePageTutorials;
