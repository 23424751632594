import React, { useEffect, useState } from "react";
import about_img_1 from "../../styletheme/public/images/about-1-img.jpg";
import about_img_2 from "../../styletheme/public/images/about-2-img.jpg";
import img_16 from "../../styletheme/public/images/img-16.png";
import img_17 from "../../styletheme/public/images/img-17.png";
import img_21 from "../../styletheme/public/images/img-21.png";
import img_24 from "../../styletheme/public/images/img-24.png";
import img_25 from "../../styletheme/public/images/img-25.png";
import img_15 from "../../styletheme/public/images/img-15.png";
import team_1 from "../../styletheme/public/images/team-1.jpg";
import team_2 from "../../styletheme/public/images/team-2.jpg";
import team_3 from "../../styletheme/public/images/team-3.jpg";
import team_4 from "../../styletheme/public/images/team-4.jpg";
import team_5 from "../../styletheme/public/images/team-5.jpg";
import team_6 from "../../styletheme/public/images/team-6.jpg";
import team_7 from "../../styletheme/public/images/team-7.jpg";
import team_8 from "../../styletheme/public/images/team-8.jpg";
import author_1 from "../../styletheme/public/images/review-author-1.jpg";
import author_2 from "../../styletheme/public/images/review-author-2.jpg";
import author_3 from "../../styletheme/public/images/review-author-3.jpg";
import author_4 from "../../styletheme/public/images/review-author-4.jpg";
import author_5 from "../../styletheme/public/images/review-author-5.jpg";
import author_6 from "../../styletheme/public/images/review-author-6.jpg";
import author_7 from "../../styletheme/public/images/review-author-7.jpg";
import author_8 from "../../styletheme/public/images/review-author-8.jpg";
import brand_1 from "../../styletheme/public/images/brand-1.png";
import brand_2 from "../../styletheme/public/images/brand-2.png";
import brand_3 from "../../styletheme/public/images/brand-3.png";
import brand_4 from "../../styletheme/public/images/brand-4.png";
import brand_5 from "../../styletheme/public/images/brand-5.png";
import brand_6 from "../../styletheme/public/images/brand-6.png";

import HomepageHightlightFeatureTwo from "./homepage_components/card/homepagehightlightfeaturetwo";
import HomepageHightlightFeatureThree from "./homepage_components/card/homepagehightlightfeaturethree";
import HomepageHightlightFeatureOne from "./homepage_components/card/homepagehightlightfeatureone";

const About = () => {
  const [pageHeaderDetail, setHeaderDetail] = useState({
    title: "Relevant news about Gurenter and its offerings",
  });

  const [loadingAnimation, setLoadingAnimation] = useState({
    isloading: false,
  });

  const [uniquenessMessage, setUniquenessMessage] = useState({
    title: "What makes Gurenter Different",
    message:
      "We know the struggles of being both a landlord and a tenant. As such we strive to simplify the process for you.",
  });

  const [reasonsWeAreDifferent, setReasonsWeAreDifferent] = useState([
    {
      title: "Easy to Use",
      message:
        "Gurenter is designed to be easy to use for both landlords and tenants.",
      icon: "flaticon-check",
      marketingImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FipadExpensesNoBackground.png?alt=media&token=b6e7cfb9-f641-423f-8b15-df26631376c2",
    },
    {
      title: "Affordable",
      message:
        "Gurenter is affordable for landlords of all sizes, with pricing starting at just $5.00 per month.",
      icon: "flaticon-dollar",
      marketingImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FiPadLandscape.webp?alt=media&token=a0d5d7cc-1a18-48ad-b3fb-992104e808f3",
    },
    {
      title: "Secure",
      message:
        "Gurenter takes security seriously, with all data encrypted and stored securely.",
      icon: "flaticon-upload",
      marketingImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FProperties_No_Dashboard-Island.webp?alt=media&token=b795d436-2743-4ca0-adfd-2a7d751a22a6",
    },
  ]);

  const [ourTeam, setOurTeam] = useState({
    title: "Meet the Team",
    message:
      "We are a team of passionate individuals who are dedicated to making the lives of landlords and tenants easier.",
    teamMembers: [
      {
        firstName: "Arnett",
        lastName: "Campbell, Phd",
        role: "Co-Founder and CEO",
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FStaff%2FIMG_9762.png?alt=media&token=bc655810-6c19-4fd5-a43f-9f1968028b84",
      },
      {
        firstName: "Keijaoh",
        lastName: "Campbell, MBA",
        role: "Co-Founder and CTO",
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/authorProfilePictures%2FIMG_0037.JPG?alt=media&token=5564320c-10c0-4db6-929d-cccf3dbb76e9",
      },
      {
        firstName: "Marlee",
        lastName: "Collins",
        role: "Marketing and Communications Specialist",
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FStaff%2FIMG_7436.png?alt=media&token=5f7109dd-46fd-432d-983e-cad2021f03dc",
      },
    ],
  });

  const [aboutUsWelcomeMessage, setAboutUsWelcomeMessage] = useState({
    title: "Welcome to Gurenter",
    message:
      "Gurenter is a property management platform designed to make the lives of landlords and tenants easier. We know the struggles of being both a landlord and a tenant. As such we strive to simplify the process for you.",
  });

  const [callToActionMessage, setCallToActionMessage] = useState({
    title: "Ready to get started?",
    message:
      "Sign up today on our iOS app and continue your landlording journey with us. We are coming to more platforms soon!",
    buttonText: "Sign Up",
    buttonLink: "/signup",
    appStoreLink:
      "https://apps.apple.com/us/app/gurenter-landlord/id1601312950",
  });

  const [topThreeFeatures, setTopThreeFeatures] = useState([
    {
      title: "Manage your Properties",
      subTitle: "Handling with Ease",
      message:
        "Whether adding a new property, unit or updating an existing one. Gurenter makes it easy to manage your properties.",
      icon: "fa fa-thumbs-up",
      media: {
        mediaType: "image",
        url: "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FProperties_No_Dashboard-Island.webp?alt=media&token=b795d436-2743-4ca0-adfd-2a7d751a22a6",
        alt: "Easy to Use",
        height: "25%",
        width: "25%",
      },
      iPadImg:
        "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FiPadLandscape.webp?alt=media&token=a0d5d7cc-1a18-48ad-b3fb-992104e808f3",
      subTextDetail: {
        subName: "Multi-Platform",
        subMessage: "Your data is where you are.",
        icon: "flaticon-smartphone-1",
      },
    },
    {
      title: "Keeping Track of your Accounting",
      subTitle: "Transaction Management",
      message: "Gurenter makes it easy to track your income and expenses.",
      icon: "fa fa-lock",
      media: {
        mediaType: "image",
        url: "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FProperties_No_Dashboard-Island.webp?alt=media&token=b795d436-2743-4ca0-adfd-2a7d751a22a6",
        alt: "Easy to Use",
        height: "25%",
        width: "25%",
      },
      iPadImg:
        "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FipadExpensesNoBackground.png?alt=media&token=b6e7cfb9-f641-423f-8b15-df26631376c2",
      subTextDetail: {
        subName: "Simplified Accounting",
        subMessage: "Never miss a cent.",
        icon: "flaticon-dollar",
      },
    },
    {
      title: "Handling Tenant Maintenance",
      subTitle: "Maintenance Management",
      message:
        "Gurenter let's you manage and keep track of all your tenant requests.",
      messages: [
        "Gurenter let's you manage and keep track of all your tenant requests.",
        "Gurenter allows you to easily assign contractors to work orders.",
        "Gurenter let's you link work orders to the appropriate property unit.",
      ],
      icon: "fa fa-money",
      media: {
        mediaType: "image",
        url: "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FProperties_No_Dashboard-Island.webp?alt=media&token=b795d436-2743-4ca0-adfd-2a7d751a22a6",
        alt: "Easy to Use",
        height: "25%",
        width: "25%",
      },
      iPadImg:
        "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FCreateTicketNoBackgroundiPad.png?alt=media&token=9125570e-58b7-443e-b615-41de6782b0cb",
      subTextDetail: {
        subName: "Connecting all parties",
        subMessage: "Hassle Free Ticket Management.",
        icon: "flaticon-chat-2",
      },
    },
  ]);

  useEffect(() => {
    //start loading animation
    setLoadingAnimation({ isloading: true });
    window.scrollTo(0, 0);

    //after finish loading data from server
    //stop loading animation
    setLoadingAnimation({ isloading: false });
  }, []);
  return (
    <React.Fragment>
      {/* PRELOADER SPINNER
		============================================= */}
      {loadingAnimation.isloading && (
        <div id="loading" className="skyblue-loading">
          <div id="loading-center">
            <div id="loading-center-absolute">
              <div className="object" id="object_one"></div>
              <div className="object" id="object_two"></div>
              <div className="object" id="object_three"></div>
              <div className="object" id="object_four"></div>
            </div>
          </div>
        </div>
      )}
      {/* ABOUT-2
			============================================= */}
      <section id="about-2" className="about-section">
        <div className="bg-inner bg-lightgrey inner-page-hero division">
          <div className="container">
            {/* ABOUT-2 TITLE */}
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-8">
                <div className="about-2-title">
                  {/* Title */}
                  <h2 className="h2-xl">{aboutUsWelcomeMessage.title}</h2>

                  {/* Text */}
                  <p className="p-xl">{aboutUsWelcomeMessage.message}</p>
                </div>
              </div>
            </div>{" "}
            {/* END ABOUT-2 TITLE */}
            {/* ABOUT-2 IMAGES */}
            <div className="about-2-images">
              <div className="row row-cols-1 row-cols-md-2">
                {/* IMAGES-1 */}
                <div className="col col-md-5">
                  <img
                    className="img-fluid"
                    src={about_img_1}
                    alt="about-image"
                  />
                </div>

                {/* IMAGES-2 */}
                <div className="col col-md-7">
                  <img
                    className="img-fluid"
                    src={about_img_2}
                    alt="about-image"
                  />
                </div>
              </div>{" "}
              {/* End row */}
            </div>{" "}
            {/* END ABOUT-2 IMAGES */}
          </div>{" "}
          {/* End container */}
        </div>{" "}
        {/* End bg-inner */}
      </section>{" "}
      {/* END ABOUT-2 */}
      {/* FEATURES-4
			============================================= */}
      <section
        hidden
        id="features-4"
        className="wide-60 features-section division"
      >
        <div className="container">
          {/* SECTION TITLE */}
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="section-title title-01 mb-70">
                {/* Title */}
                <h2 className="h2-md">We’re Better. Here’s Why…</h2>

                {/* Text */}
                <p className="p-xl">
                  Aliquam a augue suscipit, luctus neque purus ipsum neque dolor
                  primis a libero tempus, blandit and cursus varius and magnis
                  sapien
                </p>
              </div>
            </div>
          </div>
          {/* FEATURES-4 WRAPPER */}
          <div className="fbox-4-wrapper fbox-4-wide">
            <div className="row row-cols-1 row-cols-md-2">
              {/* FEATURE BOX #1 */}
              <div className="col">
                <div className="fbox-4 pr-25 mb-40 wow fadeInUp">
                  {/* Icon */}
                  <div className="fbox-ico">
                    <div className="ico-65">
                      <span className="flaticon-line-graph-1"></span>
                    </div>
                  </div>

                  {/* Text */}
                  <div className="fbox-txt">
                    {/* Title */}
                    <h5 className="h5-md">Market Research</h5>

                    {/* Text */}
                    <p className="p-lg">
                      Porta semper lacus cursus feugiat primis ultrice ligula
                      risus auctor tempus feugiat impedit undo auctor felis
                      augue mauris aoreet tempor
                    </p>
                  </div>
                </div>
              </div>

              {/* FEATURE BOX #2 */}
              <div className="col">
                <div className="fbox-4 pl-25 mb-40 wow fadeInUp">
                  {/* Icon */}
                  <div className="fbox-ico">
                    <div className="ico-65">
                      <span className="flaticon-idea"></span>
                    </div>
                  </div>

                  {/* Text */}
                  <div className="fbox-txt">
                    {/* Title */}
                    <h5 className="h5-md">User Experience</h5>

                    {/* Text */}
                    <p className="p-lg">
                      Porta semper lacus cursus feugiat primis ultrice ligula
                      risus auctor tempus feugiat impedit undo auctor felis
                      augue mauris aoreet tempor
                    </p>
                  </div>
                </div>
              </div>

              {/* FEATURE BOX #3 */}
              <div className="col">
                <div className="fbox-4 pr-25 mb-40 wow fadeInUp">
                  {/* Icon */}
                  <div className="fbox-ico">
                    <div className="ico-65">
                      <span className="flaticon-algorithm"></span>
                    </div>
                  </div>

                  {/* Text */}
                  <div className="fbox-txt">
                    {/* Title */}
                    <h5 className="h5-md">Web Development</h5>

                    {/* Text */}
                    <p className="p-lg">
                      Porta semper lacus cursus feugiat primis ultrice ligula
                      risus auctor tempus feugiat impedit undo auctor felis
                      augue mauris aoreet tempor
                    </p>
                  </div>
                </div>
              </div>

              {/* FEATURE BOX #4 */}
              <div className="col">
                <div className="fbox-4 pl-25 mb-40 wow fadeInUp">
                  {/* Icon */}
                  <div className="fbox-ico">
                    <div className="ico-65">
                      <span className="flaticon-increase-1"></span>
                    </div>
                  </div>

                  {/* Text */}
                  <div className="fbox-txt">
                    {/* Title */}
                    <h5 className="h5-md">Digital Marketing</h5>

                    {/* Text */}
                    <p className="p-lg">
                      Porta semper lacus cursus feugiat primis ultrice ligula
                      risus auctor tempus feugiat impedit undo auctor felis
                      augue mauris aoreet tempor
                    </p>
                  </div>
                </div>
              </div>

              {/* FEATURE BOX #5 */}
              <div className="col">
                <div className="fbox-4 pr-25 mb-40 wow fadeInUp">
                  {/* Icon */}
                  <div className="fbox-ico">
                    <div className="ico-65">
                      <span className="flaticon-reward"></span>
                    </div>
                  </div>

                  {/* Text */}
                  <div className="fbox-txt">
                    {/* Title */}
                    <h5 className="h5-md">Brand Design Identity</h5>

                    {/* Text */}
                    <p className="p-lg">
                      Porta semper lacus cursus feugiat primis ultrice ligula
                      risus auctor tempus feugiat impedit undo auctor felis
                      augue mauris aoreet tempor
                    </p>
                  </div>
                </div>
              </div>

              {/* FEATURE BOX #6 */}
              <div className="col">
                <div className="fbox-4 pl-25 mb-40 wow fadeInUp">
                  {/* Icon */}
                  <div className="fbox-ico">
                    <div className="ico-65">
                      <span className="flaticon-seo"></span>
                    </div>
                  </div>

                  {/* Text */}
                  <div className="fbox-txt">
                    {/* Title */}
                    <h5 className="h5-md">SEO & SMM Services</h5>

                    {/* Text */}
                    <p className="p-lg">
                      Porta semper lacus cursus feugiat primis ultrice ligula
                      risus auctor tempus feugiat impedit undo auctor felis
                      augue mauris aoreet tempor
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* END FEATURES-4 WRAPPER */}
        </div>{" "}
        {/* End container */}
      </section>{" "}
      {/* END FEATURES-4 */}
      {/* DIVIDER LINE */}
      <hr hidden className="divider" />
      {/* STATISTIC-4
			============================================= */}
      <div
        hidden
        id="statistic-4"
        className="pt-70 pb-70 statistic-section division"
      >
        <div className="container">
          {/* STATISTIC-4 WRAPPER */}
          <div className="statistic-4-wrapper">
            <div className="row justify-content-md-center row-cols-1 row-cols-md-3">
              {/* STATISTIC BLOCK #1 */}
              <div id="sb-4-1" className="col">
                <div className="statistic-block pr-15 d-flex align-items-center wow fadeInUp">
                  {/* Digit */}
                  <div className="statistic-block-digit">
                    <h2 className="h2-lg statistic-number">
                      <span className="count-element">65</span>K
                    </h2>
                  </div>

                  {/* Text */}
                  <div className="statistic-block-txt grey-color">
                    <h6 className="h6-md">
                      Porta justo integer and velna vitae auctor
                    </h6>
                  </div>
                </div>
              </div>

              {/* STATISTIC BLOCK #2 */}
              <div id="sb-4-2" className="col">
                <div className="statistic-block pr-15 d-flex align-items-center wow fadeInUp">
                  {/* Digit */}
                  <div className="statistic-block-digit">
                    <h2 className="h2-lg statistic-number">
                      <span className="count-element">54</span>%
                    </h2>
                  </div>

                  {/* Text */}
                  <div className="statistic-block-txt grey-color">
                    <h6 className="h6-md">
                      Ligula magna suscipit vitae and rutrum
                    </h6>
                  </div>
                </div>
              </div>

              {/* STATISTIC BLOCK #3 */}
              <div id="sb-4-3" className="col">
                <div className="statistic-block pr-15 d-flex align-items-center wow fadeInUp">
                  {/* Digit */}
                  <div className="statistic-block-digit">
                    <h2 className="h2-lg statistic-number">
                      <span className="count-element">4</span>.
                      <span className="count-element">86</span>
                    </h2>
                  </div>

                  {/* Text */}
                  <div className="statistic-block-txt grey-color">
                    <h6 className="h6-md">
                      Sagittis congue augue egestas an egestas
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* END STATISTIC-4 WRAPPER */}
        </div>{" "}
        {/* End container */}
      </div>{" "}
      {/* END STATISTIC-4 */}
      {/* DIVIDER LINE */}
      <hr className="divider" />
      {/* CONTENT-3
			============================================= */}
      {topThreeFeatures?.length > 0 && (
        <>
          <section className="container wide-60">
            <div className="row justify-content-left">
              <HomepageHightlightFeatureOne
                headerDetail={topThreeFeatures[0]}
              />
            </div>
          </section>

          {/* DIVIDER LINE */}
          <hr class="divider"></hr>
        </>
      )}
      {topThreeFeatures?.length > 1 && (
        <>
          <section className="container wide-60">
            <div className="row justify-content-left">
              <HomepageHightlightFeatureTwo
                headerDetail={topThreeFeatures[1]}
              />
            </div>
          </section>

          {/* DIVIDER LINE */}
          <hr class="divider"></hr>
        </>
      )}
      {/* END CONTENT-3 */}
      {/* FEATURES-8
			============================================= */}
      <section id="features-8" className="pb-60 features-section division">
        <div className="container">
          {/* SECTION TITLE */}
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="section-title title-01 mb-70">
                {/* Title */}
                <h2 className="h2-md">{uniquenessMessage.title}</h2>

                {/* Text */}
                <p className="p-xl">{uniquenessMessage.message}</p>
              </div>
            </div>
          </div>
          {/* FEATURES-8 WRAPPER */}
          <div className="fbox-8-wrapper text-center">
            <div className="row row-cols-1 row-cols-md-3">
              {reasonsWeAreDifferent.map((reason, index) => {
                return (
                  <div className="col">
                    <div className="fbox-8 mb-40 wow fadeInUp">
                      {/* Image */}
                      <div className="fbox-img bg-whitesmoke-gradient">
                        <img
                          className="img-fluid"
                          src={reason.marketingImageUrl}
                          alt="feature-icon"
                          style={{
                            width: "100%",
                            height: "270px",
                            objectFit: "cover",
                          }}
                        />
                      </div>

                      {/* Title */}
                      <h5 className="h5-md">{reason.title}</h5>

                      {/* Text */}
                      <p className="p-lg">{reason.message}</p>
                    </div>
                  </div>
                );
              })}
            </div>{" "}
            {/* End row */}
          </div>{" "}
          {/* END FEATURES-8 WRAPPER */}
        </div>{" "}
        {/* End container */}
      </section>{" "}
      {/* END FEATURES-8 */}
      {/* CONTENT-5
			============================================= */}
      {topThreeFeatures?.length > 2 && (
        <>
          <section className="container wide-60">
            <HomepageHightlightFeatureThree
              headerDetail={topThreeFeatures[2]}
            />
          </section>

          {/* DIVIDER LINE */}
          <hr class="divider"></hr>
        </>
      )}
      {/* END CONTENT-5 */}
      {/* TEAM-1
			============================================= */}
      <section id="team-1" className="wide-50 team-section division">
        <div className="container">
          {/* SECTION TITLE */}
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="section-title title-01 mb-80">
                {/* Title */}
                <h2 className="h2-md">{ourTeam.title}</h2>

                {/* Text */}
                <p className="p-xl">{ourTeam.message}</p>
              </div>
            </div>
          </div>
          {/* TEAM MEMBERS HOLDER */}
          <div className="team-members-wrapper text-center">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
              {ourTeam.teamMembers.map((member, index) => {
                return (
                  <div className="col">
                    <div className="team-member wow fadeInUp">
                      {/* Team Member Photo */}
                      <div className="team-member-photo">
                        <img
                          className="img-fluid"
                          src={member.imageUrl}
                          alt="team-member-foto"
                          style={{ height: "306px", width: "306px" }}
                        />
                      </div>

                      {/* Team Member Data */}
                      <div className="team-member-data">
                        <h5 className="h5-sm">
                          {member.firstName} {member.lastName}
                        </h5>
                        <p className="p-lg">{member.role}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>{" "}
            {/* End row */}
          </div>{" "}
          {/* TEAM MEMBERS HOLDER */}
        </div>{" "}
        {/* End container */}
      </section>{" "}
      {/* END TEAM-1 */}
      {/* TESTIMONIALS-1
			=============================================  In the future*/}
      {/* CALL TO ACTION-5
			============================================= */}
      <section id="cta-5" className="cta-section division">
        <div className="container">
          <div className="rel bg-04 cta-5-wrapper">
            <div className="row justify-content-center">
              {/* CALL TO ACTION TEXT */}
              <div className="col-lg-8">
                <div className="cta-5-txt white-color text-center">
                  {/* Title */}
                  <h2 className="h2-xs">{callToActionMessage.title}</h2>

                  {/* Text */}
                  <p className="p-xl">{callToActionMessage.message}</p>

                  {/* Button */}
                  <a
                    href={callToActionMessage.appStoreLink}
                    target="_blank"
                    className="btn btn-skyblue tra-white-hover"
                  >
                    Get Started Now
                  </a>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* End row */}
        </div>{" "}
        {/* End container */}
      </section>{" "}
      {/* END CALL TO ACTION-5 */}
    </React.Fragment>
  );
};

export default About;
