import React from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../../styletheme/logos/gurenter_logo.png";
import sm_logo from "../../../styletheme/logos/gurenter_logo.png";
import "../../../styletheme/public/css/bootstrap.min.css";
import "../../../styletheme/public/css/flaticon.css";
import "../../../styletheme/public/css/menu.css";

const NavBar = ({ admin, currentProfile }) => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
      style={{ paddingLeft: "10px", paddingRight: "10px" }}
    >
      <Navbar.Brand as={Link} to="/" style={{ paddingLeft: "10px" }}>
        <img
          src={logo}
          width="150"
          height="70"
          className="d-inline-block align-top"
          alt="Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        style={{ paddingRight: "10px" }}
      />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        style={{ justifyContent: "center" }}
      >
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/">
            Home
          </Nav.Link>

          <Nav.Link as={Link} to="/about">
            About
          </Nav.Link>
          {/*
					<NavDropdown title='For Landlords' id='basic-nav-dropdown'>
						<NavDropdown.Item href='about.html'>About Us</NavDropdown.Item>
						<NavDropdown.Item href='features.html'>
							Features & Addons
						</NavDropdown.Item>
						<NavDropdown.Item href='pricing.html'>
							Pricing Packages
						</NavDropdown.Item>
						<NavDropdown.Item href='download.html'>
							Download Page
						</NavDropdown.Item>
						<NavDropdown.Item href='projects.html'>
							Our Projects
						</NavDropdown.Item>
						<NavDropdown.Item href='project-details.html'>
							Project Details
						</NavDropdown.Item>
					</NavDropdown>
					<NavDropdown title='For Tenants' id='basic-nav-dropdown'>
						<NavDropdown.Item as={Link} to='/tenantapplication'>
							Rental Application Form
						</NavDropdown.Item>
					</NavDropdown>
					*/}
          <Nav.Link as={Link} to="/blogs">
            Blogs
          </Nav.Link>
          <Nav.Link as={Link} to="/tutorials">
            Tutorials
          </Nav.Link>
          <Nav.Link as={Link} to="/contactus">
            Contact Us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
