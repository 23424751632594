import React from "react";
import { Link } from "react-router-dom";

const HomePageLatestArticles = ({ articles }) => {
  return (
    <section id="blog-2" className="blog-section division">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb-40">
              <h2 className="h2-xs">Gurenter Latest News</h2>
              <p className="p-md">keep up with our new features and releases</p>
            </div>
          </div>
        </div>
        <div className="row">
          {articles &&
            articles.map((article, index) => (
              <div key={index} className="col-md-6 col-lg-4">
                <div className="blog-post">
                  <div className="blog-post-img">
                    <Link to={`/blog/${article.blogTitle}`}>
                      <img
                        className="img-fluid"
                        src={article.blogPromoImg}
                        alt={article.blogTitle}
                        style={{
                          height: 260,
                          width: 389,
                          objectFit: "contain",
                        }}
                      />
                    </Link>
                  </div>
                  <div className="blog-post-txt" style={{ paddingTop: "10px" }}>
                    <Link to={`/blog/${article.blogTitle}`}>
                      <h5 className="h5-md">{article.blogTitle}</h5>
                    </Link>
                    <p className="post-meta">
                      {new Date(article.recordCreatedDate).getDate()}{" "}
                      {new Date(article.recordCreatedDate).toLocaleString(
                        "default",
                        {
                          month: "long",
                        }
                      )}{" "}
                      {new Date(article.recordCreatedDate).getFullYear()}
                    </p>
                    <p className="p-sm">
                      {article.blogOverview.length > 200
                        ? article.blogOverview.slice(0, 200) + "..."
                        : article.blogOverview}
                    </p>
                    <Link
                      to={`/blog/${article.blogTitle}`}
                      className="btn btn-primary"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default HomePageLatestArticles;
