import http from "../../general/httpService";
import api from "../../../constants/config.json";


export function fetchAllBlogs() {
	
	const fullApiEndpoint =
		api.apiEndpoint + "/blogManagement/api/getAllBlogs";
	return http.get(fullApiEndpoint);
}

export function fetchHighlightedBlog() {
	const fullApiEndpoint =
		api.apiEndpoint + "/blogManagement/api/getHighlightedBlog";
	return http.get(fullApiEndpoint);
}

export function fetchBlogByTitle(title) {
	const fullApiEndpoint =
		api.apiEndpoint + "/blogManagement/api/getBlogByTitle/" + title;
	return http.get(fullApiEndpoint);
}

export function fetchLatestBlogs(numberOfBlogs) {
	const fullApiEndpoint =
		api.apiEndpoint + "/blogManagement/api/getLatestBlogs/" + numberOfBlogs;
	return http.get(fullApiEndpoint);
}

export function getLatestBlogsAndExclude(blogId, numberOfBlogs) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/blogManagement/api/fetchLatestBlogsAndExclude/" +
		blogId +
		"/" +
		numberOfBlogs;
	return http.get(fullApiEndpoint);
}

export function getLatestBlogs(numberOfBlogs){
	const fullApiEndpoint =
		api.apiEndpoint +
		"/blogManagement/api/getLatestBlogs/" +
		numberOfBlogs;
	return http.get(fullApiEndpoint);
}

export function getAllBlogsPaginated(pageNumber, limit) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/blogManagement/api/fetch_blogs_using_pagination/" +
		pageNumber +
		"/" +
		limit;
	return http.get(fullApiEndpoint);
}
