import React from "react";

const TextArea = ({
  name,
  label,
  value,
  placeholder,
  type,
  error,
  onChange,
}) => {
  return (
    <div className="form-group">
      <label className="form-label">
        {label}
        <span className="text-danger">*</span>
      </label>

      <div className="form-icon position-relative">
        <textarea
          type={type}
          id={name}
          onChange={onChange}
          value={value}
          required
          data-error={error}
          className="form-control "
          placeholder={placeholder}
          name={name}
          cols="30"
          rows="5"
        />
        {error && <div className="help-block with-errors"></div>}
      </div>
    </div>
  );
};

export default TextArea;
