import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import logo from "./logo.svg";
//import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "./components/public_pages/homepage";
import About from "./components/public_pages/about";
import NavBar from "./components/common/navigation/navigation_bar";
import GeneralFooter from "./components/common/navigation/general_footer";
import ContactUs from "./components/public_pages/contact/contactus";
import TenantApplication from "./components/portal/tenant/tenantapplication";
import Blogs from "./components/public_pages/blogs/blogs";
import BlogDetail from "./components/public_pages/blogs/detail/blogdetail";
import NotFoundPage from "./components/public_pages/notfound/notfoundpage";
import Tutorials from "./components/public_pages/tutorials/general/tutorials";
import TutorialDetail from "./components/public_pages/tutorials/general/detail/tutorialdetail";
//Check these out
/*
https://react-chartjs-2.js.org/examples
*/
//blogs promo, new releases etc
//tutorials, specfic to landlords,tenants and platform specific

class App extends Component {
	state = {
	termsAndConditionsLink: "https://gurenter.notion.site/Gurenter-Terms-and-Conditions-750bc6ea4f5f46e08b52defa4d8c29da?pvs=4",
	privacyPolicyLink: "https://gurenter.notion.site/Gurenter-Privacy-Policy-b9693560e67b476c9bc917478b657480?pvs=4",


	};


	async componentDidMount() {}

	render() {
		const { termsAndConditionsLink,privacyPolicyLink } = this.state;

		return (
			<React.Fragment>
				<body>

 <ToastContainer />

					<div id='page' className='page'>
						<NavBar  />
						<Routes>
							<Route path='/' element={<HomePage />} />
							<Route path='/about' element={<About />} />
							<Route path='/contactus' element={<ContactUs />} />
							<Route
								path='/tenantapplication'
								element={<TenantApplication />}
							/>
							<Route path='/blogs' element={<Blogs />} />
						
							<Route path="/blog" element={<BlogDetail   />}>
								<Route path=":blogTitle" element={<BlogDetail  />} />
							</Route>

							<Route path='/tutorials' element={<Tutorials />} />

							<Route path="/tutorial" element={<TutorialDetail   />}>
								<Route path=":tutorialTitle" element={<TutorialDetail  />} />
							</Route>

							<Route path='/*' element={<NotFoundPage />} />
						</Routes>

						<GeneralFooter termsAndConditionsLink={termsAndConditionsLink} privacyPolicyLink={privacyPolicyLink} />
					</div>
				</body>
			</React.Fragment>
		);
	}
}

export default App;
