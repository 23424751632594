import React, { Component } from "react";
import img25 from "../../../styletheme/public/images/contactus/contactUsImg.png";
import { Link } from "react-router-dom";

class HomePageCallToAction extends Component {
  state = {
    test: {
      title: "test",
    },
  };

  async componentDidMount() {
    //set at the top of the screen
    window.scrollTo(0, 0);
  }

  render() {
    const { test } = this.state;
    return (
      <React.Fragment>
        {/* CALL TO ACTION-11
			============================================= */}
        <section id="cta-11" className="bg-snow cta-section division">
          <div className="container">
            <div className="bg-tra-purple cta-11-wrapper">
              <div className="row d-flex align-items-center">
                {/* CALL TO ACTION TEXT */}
                <div className="col-lg-7 col-lg-7">
                  <div className="cta-11-txt">
                    {/* Title */}
                    <h2 className="h2-xs">Have questions about Gurenter?</h2>

                    {/* Text */}
                    <p className="p-lg">
                      Send us a message and we will answer your questions as
                      soon as possible.
                    </p>

                    {/* Button */}
                    <Link
                      to={"/contactus"}
                      className="btn btn-violet-red tra-violet-red-hover"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>

                {/* CALL TO ACTION BUTTON */}
                <div className="col-lg-5">
                  <div className="text-end">
                    <div className="cta-11-img text-center">
                      <img className="img-fluid" src={img25} alt="cta-image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* End row */}
          </div>{" "}
          {/* End container */}
        </section>{" "}
        {/* END CALL TO ACTION-11 */}
      </React.Fragment>
    );
  }
}

export default HomePageCallToAction;
