import React from "react";
import "../../../App.css";

const InputWithoutLabel = ({
  name,
  value,
  placeholder,
  type,
  className,
  error,
  onChange,
}) => {
  return (
    <React.Fragment>
      <input
        className={className}
        type={type}
        id={name}
        onChange={onChange}
        value={value}
        required
        data-error={error}
        placeholder={placeholder}
        name={name}
      />
    </React.Fragment>
  );
};

export default InputWithoutLabel;
