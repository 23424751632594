import React, { Component } from "react";
import uuid from "react-uuid";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Form from "../form";
import { SaveNewsLetterEmail } from "../../../../services/contact/contactService";

import InputWithoutLabel from "../inputWithoutLabel";

class NewsletterForm extends Form {
  state = {
    data: {
      email: "",
    },
    errors: {},
    baseState: {},
  };
  //methods
  schema = {
    email: Joi.string().required().email().label("Email Address"),
  };

  //set initial state to reset form on successful submit
  constructor(props) {
    super(props);

    // preserve the initial state in a new object
    this.baseState = this.state; ///>>>>>>>>> note this one.
  }
  resetForm = () => {
    this.setState(this.baseState); ///>>>>>>>>> note this one.
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      //call the api method

      const email = data.email;
      const date = Date().toString(); //convert to string
      const newsLetterRequest = {
        emailId: uuid(), //set a random ID
        email: email,
        date: date,
      };

      try {
        await SaveNewsLetterEmail(newsLetterRequest);

        this.resetForm();

        //Thank user
        toast.success("You have been added to the Newsletter. Thank you!");
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          const errors = { ...this.state.errors };
          errors.videoTitle = ex.response.data;
          toast.error(ex.response.data);
          //Update the UI
          this.setState({ errors });
        }
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.videoTitle = ex.response.data;

        toast.error(ex.response.data);
        //Update the UI
        this.setState({ errors });
      }
    }
  };

  componentDidMount() {
    //set at the top of the screen
    window.scrollTo(0, 0);
  }

  render() {
    const { data, errors } = this.state;
    return (
      <React.Fragment>
        {/* NEWSLETTER-1
			============================================= */}
        <section id="newsletter-1" className="newsletter-section division">
          <div className="container">
            <div className="newsletter-wrapper bg-white">
              <div className="row d-flex align-items-center row-cols-1 row-cols-lg-2">
                {/* NEWSLETTER TEXT */}
                <div className="col">
                  <div className="newsletter-txt pr-20">
                    <h4 className="h4-xl">
                      Stay up to date with our news, ideas and updates
                    </h4>
                  </div>
                </div>
                {/* NEWSLETTER FORM */}
                <div className="col">
                  <form
                    onSubmit={this.handleSubmit}
                    className="newsletter-form"
                  >
                    <div className="input-group">
                      <InputWithoutLabel
                        name="email"
                        type="email"
                        error={errors.email}
                        value={data.email}
                        placeholder="Email Address"
                        label="Email Address"
                        className={"form-control"}
                        onChange={this.handleChange}
                      />

                      <span className="input-group-btn">
                        {this.renderButton(
                          " Subscribe Now",
                          "btn btn-md btn-skyblue tra-grey-hover"
                        )}
                      </span>
                    </div>
                  </form>
                </div>{" "}
                {/* END NEWSLETTER FORM */}
              </div>{" "}
              {/* End row */}
            </div>{" "}
            {/* End newsletter-wrapper */}
          </div>{" "}
          {/* End container */}
        </section>{" "}
        {/* END NEWSLETTER-1 */}
      </React.Fragment>
    );
  }
}
export default NewsletterForm;
