//

import React, { Component } from "react";
import img7 from "../../../../styletheme/public/images/img-07.png";

const HomepageHightlightFeatureThree = ({ headerDetail }) => {
  return (
    <React.Fragment>
      {/* CONTENT-1
			============================================= */}
      <section
        id="content-1"
        class="content-1 bg-04 wide-60 content-section division"
      >
        <div class="container">
          <div class="row d-flex align-items-center">
            {/* TEXT BLOCK */}
            <div class="col-md-7 col-lg-6 order-last order-md-2">
              <div class="txt-block left-column white-color wow fadeInRight">
                {/* Section ID */}
                <span class="section-id rounded-id bg-tra-white white-color txt-upcase">
                  {headerDetail.subTitle}
                </span>

                {/* Title */}
                <h2 class="h2-xs">{headerDetail.title}</h2>

                {/* List */}
                {headerDetail.messages && headerDetail.messages.length > 1 ? (
                  headerDetail.messages.map((message) => {
                    return (
                      <ul class="simple-list">
                        <li class="list-item">
                          <p class="p-lg">{message}</p>
                        </li>
                      </ul>
                    );
                  })
                ) : (
                  <ul class="simple-list">
                    <li class="list-item">
                      <p class="p-lg">{headerDetail.message}</p>
                    </li>
                  </ul>
                )}
              </div>
            </div>{" "}
            {/* END TEXT BLOCK */}
            {/* IMAGE BLOCK */}
            <div class="col-md-5 col-lg-6 order-first order-md-2">
              <div class="rel img-block right-column wow fadeInLeft">
                <img
                  class="img-fluid"
                  src={headerDetail.iPadImg}
                  alt="content-image"
                />
              </div>
            </div>
          </div>{" "}
          {/* End row */}
        </div>{" "}
        {/* End container */}
      </section>{" "}
      {/* END CONTENT-1 */}
    </React.Fragment>
  );
};

export default HomepageHightlightFeatureThree;
