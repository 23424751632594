//just the base information like name and email and the co-applicants (adults) each adult must fill out the application
import React, { Component } from "react";
import "../../../../App.css";
import Input from "../../../common/reusuable/input";
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import Form from "../../../common/reusuable/form";

class TenantApplBasicSection extends Form {
	state = {
		data: {
			//contactRequestId: '',
			firstName: "",
			lastName: "",
			middleName: "",
			dateOfBirth: "",
		},
		errors: {},
		baseState: {},
	};
	//methods
	schema = {
		// contactRequestId: Joi.string().optional(),
		firstName: Joi.string().required().label("First Name"),
		lastName: Joi.string().required().label("Last Name"),
		middleName: Joi.string().required().label("Middle Name"),
		dateOfBirth: Joi.string().required().label("Date of Birth"),
		/*
		requestorPhoneNumber: Joi.string().required().label("Phone Number"),
		//requestorBudget: Joi.number().required().label('Budget'),
		subject: Joi.string().required().label("Subject"),
		messageDetail: Joi.string().required().label("Message Detail"),
		*/
	};

	//set initial state to reset form on successful submit
	constructor(props) {
		super(props);

		// preserve the initial state in a new object
		this.baseState = this.state; ///>>>>>>>>> note this one.
	}
	resetForm = () => {
		this.setState(this.baseState); ///>>>>>>>>> note this one.
	};

	async componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);
	}

	//MARK: Add the on submit logic here

	render() {
		const { data, errors } = this.state;
		return (
			<React.Fragment>
				{/* SIGN UP PAGE
			============================================= */}
				{/* SIGN UP FORM */}
				<div className='register-form'>
					<form
						name='signupform'
						className='container sign-up-form '
						onSubmit={this.handleSubmit}>
						{/* Title*/}
						<div className='col-md-12'>
							<div className='register-form-title'>
								<h4 className='h6-xs'>Applicant Information</h4>
							</div>
						</div>

						{/* Form Input */}
						<div className='col-lg-12'>
							<div className='row'>
								<Input
									className={"form-control"}
									name='firstName'
									type='text'
									error={errors.firstName}
									value={data.firstName}
									placeholder='First Name'
									label='First Name'
									onChange={this.handleChange}
								/>

								<Input
									className={"form-control"}
									name='middleName'
									type='text'
									error={errors.middleName}
									value={data.middleName}
									placeholder='Middle Name'
									label='Middle Name'
									onChange={this.handleChange}
								/>

								<Input
									className={"form-control"}
									name='lastName'
									type='text'
									error={errors.lastName}
									value={data.lastName}
									placeholder='Last Name'
									label='Last Name'
									onChange={this.handleChange}
								/>
								{/*Change to Picker later*/}
								<Input
									className={"form-control"}
									name='dateOfBirth'
									type='text'
									error={errors.dateOfBirth}
									value={data.dateOfBirth}
									placeholder='Your Date of Birth'
									label='Date of Birth'
									onChange={this.handleChange}
								/>
							</div>
						</div>
						{/* Form Submit Button 
						<div className='col-md-12'>
							<button
								type='submit'
								className='btn btn-md btn-skyblue tra-black-hover submit'>
								Create Account
							</button>
						</div>

					
						<div className='col-md-12'>
							<div className='form-data'>
								<div className='form-check'>
									<input
										type='checkbox'
										className='form-check-input'
										id='exampleCheck1'
										checked
									/>
									<label className='form-check-label' for='exampleCheck1'>
										<span>
											By clicking “Sign up”, you agree to our{" "}
											<a href='terms.html'>Terms</a> and that you have read our{" "}
											<a href='terms.html'> Privacy Policy</a>
										</span>
									</label>
								</div>
							</div>
						</div>
							*/}
					</form>
				</div>{" "}
				{/* END SIGN UP FORM */}
			</React.Fragment>
		);
	}
}

export default TenantApplBasicSection;
