import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import error_404_img from "../../../styletheme/public/images/error-404.png";

const NotFoundPage = () => {
	const [pageHeaderDetail, setHeaderDetail] = useState({
		title: "Relevant news about Gurenter and its offerings",
	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<React.Fragment>
			{/* HERO-24
			============================================= */}
			<section id='hero-24' class='bg-tra-blue hero-section division'>
				<div class='container'>
					<div class='row d-flex align-items-center'>
						{/* 404 PAGE TEXT */}
						<div class='col-md-8 col-lg-6 offset-md-2 offset-lg-3'>
							<div class='hero-24-txt text-center'>
								{/* Image */}
								<div class='rel hero-24-img'>
									{/* Image */}
									<img
										class='img-fluid'
										src={error_404_img}
										alt='error-404-img'
									/>

									{/* Transparent Header */}
									<h2 class='tra-header'>404</h2>
								</div>

								{/* Text */}
								<h2 class='h2-md'>Page Not Found</h2>
								<h5 class='h5-md'>
									The page you are looking for might have been moved , renamed
									or might never existed{" "}
								</h5>

								{/* Button */}
								<Link to={"/"} class='btn btn-skyblue tra-grey-hover'>
									Back To Home
								</Link>
							</div>
						</div>{" "}
						{/* END 404 PAGE TEXT */}
					</div>{" "}
					{/* End row */}
				</div>{" "}
				{/* End container */}
			</section>{" "}
			{/* END HERO-19 */}
		</React.Fragment>
	);
};

export default NotFoundPage;
