import React, { useEffect, useState } from "react";
import CondensePriceChart from "../common/pricing/condensepricechart";
import HomePageHero from "./homepage_components/homepagehero";
import HomePageLatestArticles from "./homepage_components/homepagelatestarticles";
import HomePageCallToAction from "./homepage_components/hopepagecalltoaction";
import TopGurenterFeatures from "./homepage_components/topgurenterfeatures";
import { NavLink, Link } from "react-router-dom";
import HomepageHightlightFeatureOne from "./homepage_components/card/homepagehightlightfeatureone";
import HomepageHightlightFeatureThree from "./homepage_components/card/homepagehightlightfeaturethree";
import HomepageHightlightFeatureTwo from "./homepage_components/card/homepagehightlightfeaturetwo";
import { getLatestBlogs } from "../../services/general/blogs/blogsService";
import HomePageTutorials from "./homepage_components/homepagetutorials";
import { fetchLatestTutorials } from "../../services/general/tutorials/tutorialsService";

function HomePage() {
  //Need Network calls for these methods
  const [isLoading, setIsLoading] = useState(false);
  const [headerDetail, setHeaderDetail] = useState({
    companyName: "Gurenter",
    title: "Simplifying Landlording",
    titleSubTitle:
      "Gurenter simplifies the way rental properties are managed. It also makes it easy for Tenants to be tenants.",
    trialPeriod: "30 Days",
    currentVersion: 1.5,
    appStoreLink:
      "https://apps.apple.com/us/app/gurenter-landlord/id1601312950",
    headerPhoneImg:
      "https://firebasestorage.googleapis.com/v0/b/gurenterpromomarketing.appspot.com/o/PromoVideos%2FGurenterDemoMode.gif?alt=media&token=4e7d6e69-5551-4885-9736-4f0cdb798ad3",
    headerBackgroundImg:
      "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FProperties_No_Dashboard-Island.webp?alt=media&token=b795d436-2743-4ca0-adfd-2a7d751a22a6",
  });
  const [uniquenessMessage, setUniquenessMessage] = useState({
    title: "What makes Gurenter Different",
    message:
      "We know the struggles of being both a landlord and a tenant. As such we strive to simplify the process for you.",
  });

  const [reasonsWeAreDifferent, setReasonsWeAreDifferent] = useState([
    {
      title: "Easy to Use",
      message:
        "Gurenter is designed to be easy to use for both landlords and tenants.",
      icon: "flaticon-check",
    },
    {
      title: "Affordable",
      message:
        "Gurenter is affordable for landlords of all sizes, with pricing starting at just $5.00 per month.",
      icon: "flaticon-dollar",
    },
    {
      title: "Secure",
      message:
        "Gurenter takes security seriously, with all data encrypted and stored securely.",
      icon: "flaticon-upload",
    },
  ]);

  const [topThreeFeatures, setTopThreeFeatures] = useState([
    {
      title: "Manage your Properties",
      subTitle: "Handling with Ease",
      message:
        "Whether adding a new property, unit or updating an existing one. Gurenter makes it easy to manage your properties.",
      icon: "fa fa-thumbs-up",
      media: {
        mediaType: "image",
        url: "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FProperties_No_Dashboard-Island.webp?alt=media&token=b795d436-2743-4ca0-adfd-2a7d751a22a6",
        alt: "Easy to Use",
        height: "25%",
        width: "25%",
      },
      iPadImg:
        "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FiPadLandscape.webp?alt=media&token=a0d5d7cc-1a18-48ad-b3fb-992104e808f3",
      subTextDetail: {
        subName: "Multi-Platform",
        subMessage: "Your data is where you are.",
        icon: "flaticon-smartphone-1",
      },
    },
    {
      title: "Keeping Track of your Accounting",
      subTitle: "Transaction Management",
      message: "Gurenter makes it easy to track your income and expenses.",
      icon: "fa fa-lock",
      media: {
        mediaType: "image",
        url: "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FProperties_No_Dashboard-Island.webp?alt=media&token=b795d436-2743-4ca0-adfd-2a7d751a22a6",
        alt: "Easy to Use",
        height: "25%",
        width: "25%",
      },
      iPadImg:
        "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FipadExpensesNoBackground.png?alt=media&token=b6e7cfb9-f641-423f-8b15-df26631376c2",
      subTextDetail: {
        subName: "Simplified Accounting",
        subMessage: "Never miss a cent.",
        icon: "flaticon-dollar",
      },
    },
    {
      title: "Handling Tenant Maintenance",
      subTitle: "Maintenance Management",
      message:
        "Gurenter let's you manage and keep track of all your tenant requests.",
      messages: [
        "Gurenter let's you manage and keep track of all your tenant requests.",
        "Gurenter allows you to easily assign contractors to work orders.",
        "Gurenter let's you link work orders to the appropriate property unit.",
      ],
      icon: "fa fa-money",
      media: {
        mediaType: "image",
        url: "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FProperties_No_Dashboard-Island.webp?alt=media&token=b795d436-2743-4ca0-adfd-2a7d751a22a6",
        alt: "Easy to Use",
        height: "25%",
        width: "25%",
      },
      iPadImg:
        "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FCreateTicketNoBackgroundiPad.png?alt=media&token=9125570e-58b7-443e-b615-41de6782b0cb",
      subTextDetail: {
        subName: "Connecting all parties",
        subMessage: "Hassle Free Ticket Management.",
        icon: "flaticon-chat-2",
      },
    },
  ]);

  const [homePageLatestArticles, setHomePageLatestArticles] = useState([
    {
      categoryName: "",
      blogId: "",
      blogTitle: "",
      blogDateTime: "",
      blogOverview: "",
      blogPromoImg: "", //web optimised
      blogSections: [
        {
          //todo soon
        },
      ],
      numberOfComments: 0,
    },
  ]);

  const [Tutorials, setTutorials] = useState([
    {
      tutorialId: "",
      tutorialTitle: "",
      videoUploadDate: "",
      youTubeVideoCode: "",
      tutorialOverview: "",
      tutorialPromoImage: "",
      deviceSpecificImages: [
        {
          deviceType: "",
          deviceImageUrl: "",
        },
      ],
      tutorialTags: [
        {
          tagId: "",
          tagName: "",
          tagDescription: "",
        },
      ],
      commentCount: 0,
      authorName: "",
      authorProfile: {
        authorId: "",
        authorName: "",
        authorProfileImgUrl: "",
        authorProfileOverview: "",
      },
      tutorialCategories: [
        {
          categoryId: "",
          categoryName: "",
          categoryDescription: "",
        },
      ],
      tutorialSections: [
        {
          sectionId: "",
          sectionType: "",
          sectionTitle: "",
          sectionPicUrl: "",
          deviceSpecificImages: [
            {
              deviceType: "",
              deviceImageUrl: "",
            },
          ],
          sectionSectionExplanation: "",
          mathEquationLogic: "",
          sourceCodeSnippets: [
            {
              sourceCodeTitle: "",
              sourceCodeExplanation: "",
              sourceCodeDetail: "",
              languageId: "",
              languageName: "",
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: "",
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: "",
              authorProfileUrl: "",
              authorProfileOverview: "",
              authorTitle: "",
            },
          ],
          referenceDetail: "",
        },
      ],
      recordCreatedDate: "",
      isDraft: false,
      tutorialPlatform: "",
      locationInApp: "",
    },
  ]);

  useEffect(() => {
    //set at the top of the screen
    window.scrollTo(0, 0);

    findLatestBlogs();
    findLatestTutorials();
  }, []);

  const findLatestBlogs = async () => {
    setIsLoading(true);

    try {
      const { data: latestBlogs } = await getLatestBlogs(3);
      if (latestBlogs.length > 0) {
        setHomePageLatestArticles(latestBlogs);
      } else {
        // Handle the case when the array is empty
      }
    } catch (error) {
      //only in dev
      //console.log(error);
      // Handle the error
    }

    setIsLoading(false);
  };

  const findLatestTutorials = async () => {
    setIsLoading(true);

    try {
      const { data: Tutorials } = await fetchLatestTutorials(3);
      if (Tutorials.length > 0) {
        setTutorials(Tutorials);
      } else {
        // Handle the case when the array is empty
      }
    } catch (error) {
      //only in dev
      //console.log(error);
      // Handle the error
    }

    setIsLoading(false);
  };

  return (
    <>
      {/* PRELOADER SPINNER
		============================================= */}
      {isLoading && (
        <div id="loading" className="skyblue-loading">
          <div id="loading-center">
            <div id="loading-center-absolute">
              <div className="object" id="object_one"></div>
              <div className="object" id="object_two"></div>
              <div className="object" id="object_three"></div>
              <div className="object" id="object_four"></div>
            </div>
          </div>
        </div>
      )}

      <section>
        <div className="container">
          <div className="row justify-content-left">
            <HomePageHero headerDetail={headerDetail} />
          </div>
        </div>

        <section
          id="features-8"
          className="pb-60 wide-50 features-section division"
        >
          <div className="container">
            {/* SECTION TITLE */}
            <div className="row justify-content-center">
              <div className="col-lg-10 col-xl-8">
                <div className="section-title title-01 mb-70">
                  {/* Title */}
                  <h2 className="h2-md">{uniquenessMessage.title}</h2>

                  {/* Text */}
                  <p className="p-xl">{uniquenessMessage.message}</p>
                </div>
              </div>
            </div>
            <TopGurenterFeatures
              reasonsWeAreDifferent={reasonsWeAreDifferent}
            />
          </div>
        </section>
      </section>

      {/* DIVIDER LINE */}
      <hr className="divider"></hr>

      {topThreeFeatures?.length > 0 && (
        <>
          <section className="container wide-60">
            <div className="row justify-content-left">
              <HomepageHightlightFeatureOne
                headerDetail={topThreeFeatures[0]}
              />
            </div>
          </section>

          {/* DIVIDER LINE */}
          <hr className="divider"></hr>
        </>
      )}
      {/*  TODO NEXT Expense Management and Ticket Management*/}
      {topThreeFeatures?.length > 1 && (
        <>
          <section className="container wide-60">
            <div className="row justify-content-left">
              <HomepageHightlightFeatureTwo
                headerDetail={topThreeFeatures[1]}
              />
            </div>
          </section>

          {/* DIVIDER LINE */}
          <hr className="divider"></hr>
        </>
      )}
      {topThreeFeatures?.length > 2 && (
        <>
          <section className="container wide-60">
            <HomepageHightlightFeatureThree
              headerDetail={topThreeFeatures[2]}
            />
          </section>

          {/* DIVIDER LINE */}
          <hr className="divider"></hr>
        </>
      )}

      {/*
Future:
      <CondensePriceChart />

      */}

      <section className="container wide-60">
        <HomePageLatestArticles articles={homePageLatestArticles} />
      </section>

      <section className="container wide-60">
        <HomePageTutorials tutorials={Tutorials} />
      </section>

      {/* DIVIDER LINE */}
      <hr className="divider"></hr>

      <section className="container wide-60">
        <HomePageCallToAction />
      </section>
    </>
  );
}

export default HomePage;
