import http from "../../general/httpService";
import api from "../../../constants/config.json";


export function fetchAllTutorials() {
	
	const fullApiEndpoint =
		api.apiEndpoint + "/tutorialManagement/api/fetch_all_tutorials";
	return http.get(fullApiEndpoint);
}

export function getAllTutorialsPaginated(pageNumber, limit) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/tutorialManagement/api/fetch_tutorials_by_page/" +
		pageNumber +
		"/" +
		limit;
	return http.get(fullApiEndpoint);
}

export function fetchTutorialByTitle(title) {
	const fullApiEndpoint =
		api.apiEndpoint + "/tutorialManagement/api/find_tutorial_by_title/" + title;
	return http.get(fullApiEndpoint);
}

export function fetchLatestTutorials(numberOfTutorials) {
	const fullApiEndpoint =
		api.apiEndpoint + "/tutorialManagement/api/fetch_latest_tutorials/" + numberOfTutorials;
	return http.get(fullApiEndpoint);
}

export function getLatestTutorialsAndExclude(tutorialId, numberOfTutorials) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/tutorialManagement/api/fetchLatestTutorialsAndExclude/" +
		tutorialId +
		"/" +
		numberOfTutorials;
	return http.get(fullApiEndpoint);
}