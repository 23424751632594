import React, { Component } from "react";
import img9 from "../../../../styletheme/public/images/img-09.png";

import img1 from "../../../../styletheme/public/images/img-01.png";
import icoBg from "../../../../styletheme/public/images/ico-bkg.png";
import from from "../../../../styletheme/public/css/homepage/homePageFeatureOne.css";

const HomepageHightlightFeatureTwo = ({ headerDetail }) => {
  return (
    <React.Fragment>
      {/* BOTTOM ROW */}
      <div class="bottom-row">
        <div class="row d-flex align-items-center">
          {/* IMAGE BLOCK */}
          <div class="col-md-7 col-lg-6">
            {/*Test */}
            <div
              className="cbox-3 mt-20"
              style={{
                backgroundImage: `url(${headerDetail.iPadImg})`,
                backgroundSize: "cover",
              }}
            >
              {/* iPhone image */}

              <div className="iphone-image">
                <img
                  className="img-fluid"
                  style={{
                    width: `${headerDetail.media.width}`,
                    height: `${headerDetail.media.height}`,
                    visibility: "collapse ",
                  }}
                  src={headerDetail.media?.url}
                  alt="video-preview"
                />
              </div>
            </div>

            {/* Test*/}
          </div>
          {/* TEXT BLOCK */}
          <div class="col-md-5 col-lg-6">
            <div class="txt-block right-column wow fadeInLeft">
              {/* Section ID */}
              <span class="section-id rounded-id bg-tra-purple purple-color txt-upcase">
                {headerDetail.subTitle}
              </span>

              {/* Title */}
              <h2 class="h2-xs">{headerDetail.title}</h2>

              {/* Text */}
              <p class="p-lg">{headerDetail.message}</p>

              {/* CONTENT BOX #1 */}
              <div class="cbox-2 mt-20">
                {/* Icon */}
                <div class="cbox-2-ico">
                  <div class="ico-40 shape-ico violet-red-color">
                    <img class="ico-bkg" src={icoBg} alt="ico-bkg" />
                    <span class={headerDetail.subTextDetail.icon}></span>
                  </div>
                </div>

                {/* Text */}
                <div class="cbox-2-txt">
                  <h5 class="h5-xs">{headerDetail.subTextDetail.subName}</h5>
                  <p class="p-md">{headerDetail.subTextDetail.subMessage}</p>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* END TEXT BLOCK */}
        </div>
      </div>{" "}
      {/* END BOTTOM ROW */}
    </React.Fragment>
  );
};

export default HomepageHightlightFeatureTwo;
