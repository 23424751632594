import React, { Component } from "react";
//need to be webp format or tiny png
//import img2 from "../../../styletheme/public/images/img-02.png";
import appstoreImg from "../../../styletheme/public/images/appstore.png";
import googlePlayImg from "../../../styletheme/public/images/googleplay.png";

const HomePageHero = ({ headerDetail }) => {
  return (
    <React.Fragment>
      <section
        id="hero-16"
        style={{ paddingTop: "5px" }}
        className="hero-section division"
      >
        <div className="container">
          <div className="row d-flex align-items-center">
            {/* HERO IMAGE */}
            <div className="col-md-7 col-lg-6  order-last order-md-2">
              <div
                className="cbox-3 mt-20"
                style={{
                  backgroundSize: "contain",
                  backgroundImage: `url(${headerDetail.headerBackgroundImg})`,
                }}
              >
                <img
                  className="img-fluid"
                  // style={{ width: "100%", height: "100%" }}
                  src={headerDetail.headerPhoneImg}
                  alt="hero-image"
                />
              </div>
            </div>
            {/* HERO TEXT */}
            <div className="col-md-5 col-lg-6 order-first order-md-2">
              <div className="hero-16-txt">
                {/* Title */}
                <h1 className="h1-sm">{headerDetail.companyName}</h1>
                <h3 className="h3-sm">{headerDetail.title}</h3>
                {/* Text */}
                <p className="p-xl">{headerDetail.titleSubTitle}</p>
                {/* STORE BADGES */}
                <div className="stores-badge mb-25">
                  {/* AppStore */}
                  <a
                    href={headerDetail.appStoreLink}
                    target="_blank"
                    className="store"
                  >
                    <img
                      className="appstore"
                      src={appstoreImg}
                      alt="appstore-badge"
                    />
                  </a>

                  {/* Google Play */}
                  {/*
									<a href='#' className='store'>
										<img
											className='googleplay'
											src={googlePlayImg}
											alt='googleplay-badge'
										/>
									</a>
									*/}

                  {/* Aamazon Market 
									<a href="#" className="store">
										<img className="amazon" src="images/amazon.png" alt="amazon-badge" />
									</a>  */}

                  {/* Mac AppStore 
									<a href="#" className="store">
										<img className="mac-appstore" src="images/macstore.png" alt="macstore-badge" />
									</a> */}

                  {/* Microsoft Store  
									<a href="#" className="store">
										<img className="microsoft" src="images/microsoft.png" alt="microsoft-badge" />
									</a> */}
                </div>{" "}
                {/* END STORE BADGES */}
                {/* Advantages List */}
                {/*}
								<ul className='advantages'>
									<li className='first-li'>
										<p>Current version {headerDetail.currentVersion}</p>
									</li>
									<li className='last-li'>
										<p>{headerDetail.trialPeriod} Free Trial</p>
									</li>
								</ul>
								*/}
              </div>
            </div>{" "}
            {/* END HERO TEXT */}
          </div>{" "}
          {/* End row */}
        </div>{" "}
        {/* End container */}
      </section>{" "}
    </React.Fragment>
  );
};

export default HomePageHero;
