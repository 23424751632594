import React, { Component } from "react";
import visaImg from "../../../styletheme/public/images/png-icons/visa.png";
import amImg from "../../../styletheme/public/images/png-icons/am.png";
import discoverImg from "../../../styletheme/public/images/png-icons/discover.png";

import paypalImg from "../../../styletheme/public/images/png-icons/paypal.png";
import jcbImg from "../../../styletheme/public/images/png-icons/jcb.png";
import shopifyImg from "../../../styletheme/public/images/png-icons/shopify.png";

class CondensePriceChart extends Component {
	state = {
		test: {
			title: "test",
		},
	};

	async componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);
	}

	render() {
		const { test } = this.state;
		return (
			<React.Fragment>
				<section
					id='pricing-2'
					class='bg-lightgrey wide-60 pricing-section division'>
					<div class='container'>
						{/* SECTION TITLE */}
						<div class='row justify-content-center'>
							<div class='col-lg-10 col-xl-8'>
								<div class='section-title title-01 mb-80'>
									{/* Title */}
									<h2 class='h2-md'>Simple And Flexible Pricing</h2>

									{/* Text */}
									<p class='p-xl'>
										No credit card required. Change or cancel your plan anytime
									</p>
								</div>
							</div>
						</div>
						{/* PRICING TABLES */}
						<div class='pricing-2-row pc-25'>
							<div class='row row-cols-1 row-cols-md-3'>
								{/* BASIC PLAN */}
								<div class='col'>
									<div class='pricing-2-table bg-white mb-40 wow fadeInUp'>
										{/* Plan Price */}
										<div class='pricing-plan'>
											{/* Plan Title */}
											<div class='pricing-plan-title'>
												<h5 class='h5-xs'>Basic</h5>
												<h6 class='h6-sm bg-lightgrey'>Save 30%</h6>
											</div>

											{/* Price */}
											<sup class='dark-color'>$</sup>
											<span class='dark-color'>7</span>
											<sup class='validity dark-color'>
												<span>.99</span> / month
											</sup>
											<p class='p-md'>Billed as $96 per year</p>
										</div>

										{/* Plan Features  */}
										<ul class='features'>
											<li>
												<p class='p-md'>
													<span>25</span> Projects
												</p>
											</li>
											<li>
												<p class='p-md'>
													<span>10</span> mySQL Database
												</p>
											</li>
											<li>
												<p class='p-md'>
													<span>25 GB</span> of Storage
												</p>
											</li>
											<li>
												<p class='p-md'>
													<span>Premium</span> Support
												</p>
											</li>
										</ul>

										{/* Pricing Plan Button */}
										<a href='#' class='btn btn-sm btn-tra-grey tra-pink-hover'>
											Select Plan
										</a>
									</div>
								</div>{" "}
								{/* END BASIC PLAN */}
								{/* AGENCY PLAN */}
								<div class='col'>
									<div class='pricing-2-table bg-white mb-40 wow fadeInUp'>
										{/* Plan Price */}
										<div class='pricing-plan'>
											{/* Plan Title */}
											<div class='pricing-plan-title'>
												<h5 class='h5-xs'>Agency</h5>
												<h6 class='h6-sm bg-lightgrey'>Save 25%</h6>
											</div>

											{/* Price */}
											<sup class='dark-color'>$</sup>
											<span class='dark-color'>11</span>
											<sup class='validity dark-color'>
												<span>.25</span> / month
											</sup>
											<p class='p-md'>Billed as $135 per year</p>
										</div>

										{/* Plan Features  */}
										<ul class='features'>
											<li>
												<p class='p-md'>
													<span>100</span> Projects
												</p>
											</li>
											<li>
												<p class='p-md'>
													<span>25</span> mySQL Database
												</p>
											</li>
											<li>
												<p class='p-md'>
													<span>80 GB</span> of Storage
												</p>
											</li>
											<li>
												<p class='p-md'>
													<span>Premium</span> Support
												</p>
											</li>
										</ul>

										{/* Pricing Plan Button */}
										<a href='#' class='btn btn-sm btn-tra-grey tra-pink-hover'>
											Select Plan
										</a>
									</div>
								</div>{" "}
								{/* END AGENCY PLAN  */}
								{/* ADVANCED PLAN */}
								<div class='col'>
									<div class='pricing-2-table bg-white mb-40 wow fadeInUp'>
										{/* Plan Price  */}
										<div class='pricing-plan highlight'>
											{/* Plan Title */}
											<div class='pricing-plan-title'>
												<h5 class='h5-xs'>Advanced</h5>
												<h6 class='h6-sm bg-pink white-color'>Popular</h6>
											</div>

											{/* Price */}
											<sup class='dark-color'>$</sup>
											<span class='dark-color'>15</span>
											<sup class='validity dark-color'>
												<span>.99</span> / month
											</sup>
											<p class='p-md'>Billed as $199 per year</p>
										</div>

										{/* Plan Features  */}
										<ul class='features'>
											<li>
												<p class='p-md'>
													<span>Unlimited</span> Projects
												</p>
											</li>
											<li>
												<p class='p-md'>
													<span>50</span> mySQL Database
												</p>
											</li>
											<li>
												<p class='p-md'>
													<span>500 GB</span> of Storage
												</p>
											</li>
											<li>
												<p class='p-md'>
													<span>VIP</span> Support
												</p>
											</li>
										</ul>

										{/* Pricing Plan Button */}
										<a href='#' class='btn btn-sm btn-pink tra-grey-hover'>
											Select Plan
										</a>
									</div>
								</div>{" "}
								{/* END ADVANCED PLAN */}
							</div>
						</div>{" "}
						{/* END PRICING TABLES */}
						{/* PRICING NOTICE TEXT */}
						<div class='row'>
							<div class='col-lg-10 offset-lg-1'>
								<div class='pricing-notice text-center mb-40'>
									<p class='p-md'>
										The above prices do not include applicable taxes based on
										your billing address. The final price will be displayed on
										the checkout page, before the payment is completed
									</p>
								</div>
							</div>
						</div>
						{/* PAYMENT METHODS */}
						<div class='payment-methods pc-30'>
							<div class='row row-cols-1 row-cols-md-3'>
								{/* Payment Methods */}
								<div class='col col-lg-5'>
									<div class='pbox mb-40'>
										{/* Title */}
										<h6 class='h6-md'>Accepted Payment Methods</h6>

										{/* Payment Icons */}
										<ul class='payment-icons ico-50'>
											<li>
												<img src={visaImg} alt='payment-icon' />
											</li>
											<li>
												<img src={amImg} alt='payment-icon' />
											</li>
											<li>
												<img src={discoverImg} alt='payment-icon' />
											</li>
											<li>
												<img src={paypalImg} alt='payment-icon' />
											</li>
											<li>
												<img src={jcbImg} alt='payment-icon' />
											</li>
											<li>
												<img src={shopifyImg} alt='payment-icon' />
											</li>
										</ul>
									</div>
								</div>

								{/* Payment Guarantee */}
								<div class='col col-lg-4'>
									<div class='pbox mb-40'>
										{/* Title */}
										<h6 class='h6-md'>Money Back Guarantee</h6>

										{/* Text */}
										<p>
											Explore OLMO Premium for 14 days. If it’s not a perfect
											fit, receive a full refund.
										</p>
									</div>
								</div>

								{/* Payment Encrypted */}
								<div class='col col-lg-3'>
									<div class='pbox mb-40'>
										{/* Title */}
										<h6 class='h6-md'>SSL Encrypted Payment</h6>

										{/* Text */}
										<p>
											Your information is protected by 256-bit SSL encryption.
										</p>
									</div>
								</div>
							</div>
						</div>{" "}
						{/* END PAYMENT METHODS */}
					</div>{" "}
					{/* End container */}
				</section>
			</React.Fragment>
		);
	}
}

export default CondensePriceChart;
