import React, { Component } from "react";
import TenantApplBasicSection from "./subviews/tenantapplbasicsection";
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
import { toast } from "react-toastify";

class TenantApplication extends Component {
	//TODO next need to implement this form, to populate the iOS App Screen
	state = {
		data: {
			//contactRequestId: '',
			requestorName: "",
			requestorEmailAddress: "",
			requestorPhoneNumber: "",
			subject: "",
			messageDetail: "", //recordCreatedDate: ''
		},
		errors: {},
		baseState: {},
	};
	//methods
	schema = {
		// contactRequestId: Joi.string().optional(),
		requestorName: Joi.string().required().label("Requestor Name"),
		requestorEmailAddress: Joi.string().required().label("Email Address"),
		requestorPhoneNumber: Joi.string().required().label("Phone Number"),
		//requestorBudget: Joi.number().required().label('Budget'),
		subject: Joi.string().required().label("Subject"),
		messageDetail: Joi.string().required().label("Message Detail"),
	};

	//set initial state to reset form on successful submit
	constructor(props) {
		super(props);

		// preserve the initial state in a new object
		this.baseState = this.state; ///>>>>>>>>> note this one.
	}
	resetForm = () => {
		this.setState(this.baseState); ///>>>>>>>>> note this one.
	};

	async componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);
	}

	render() {
		const { test } = this.state;
		return (
			<React.Fragment>
				{/*Need a form switcher */}
				<section id='signup-1' className='signup-section division'>
					<div className='container'>
						<div className='row' style={{ paddingTop: "10px" }}>
							<div className='col-md-8 col-lg-6 offset-md-2 offset-lg-3'>
								<h1>Rental Application</h1>
							</div>

							<section>
								{/* Change these to take parameters and send them back to the main tenant screen here*/}
								<TenantApplBasicSection />
								<TenantApplBasicSection />
							</section>
						</div>
					</div>
				</section>
			</React.Fragment>
		);
	}
}

export default TenantApplication;
