import React, { Component } from "react";
import "../../../styletheme/public/css/style.css";

const TopGurenterFeatures = ({ reasonsWeAreDifferent }) => {
  return (
    <React.Fragment>
      <section
        id="features-4"
        className="wide-50 features-section division"
        style={{ paddingTop: "0px" }}
      >
        <div className="container">
          {/* FEATURES-4 WRAPPER */}
          <div className="fbox-4-wrapper fbox-4-wide">
            <div className="row row-cols-1 row-cols-md-2">
              {/* FEATURE BOX #1 Loop start */}
              {reasonsWeAreDifferent.map((value, index) => (
                <div className="col">
                  <div className="fbox-4 pr-25 mb-40 wow fadeInUp">
                    {/* Icon */}
                    <div className="fbox-ico">
                      <div className="ico-65">
                        <span className={`${value.icon}`}></span>
                      </div>
                    </div>

                    {/* Text */}
                    <div className="fbox-txt">
                      {/* Title */}
                      <h5 className="h5-md">{value.title}</h5>

                      {/* Text */}
                      <p className="p-lg">{value.message}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>{" "}
          {/* END FEATURES-4 WRAPPER */}
        </div>{" "}
        {/* End container */}
      </section>{" "}
    </React.Fragment>
  );
};

export default TopGurenterFeatures;
