import React from "react";
import ReactDOM from "react-dom/client";


import "../src/styletheme/public/css/style.css";
import "./index.css";
import "../src/styletheme/public/css/bootstrap.min.css";

import "../src/styletheme/public/css/flaticon.css";
import "../src/styletheme/public/css/menu.css";
import "../src/styletheme/public/css/dropdown-effects/fade-down.css";
import "../src/styletheme/public/css/magnific-popup.css";
import "../src/styletheme/public/css/owl.carousel.min.css";
import "../src/styletheme/public/css/owl.theme.default.min.css";
import "../src/styletheme/public/css/animate.css";



import "../src/styletheme/public/css/responsive.css";
//import "bootstrap/dist/css/bootstrap.min.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
